<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog
          v-model="dialogDuplicateTestSet"
          scrollable
          persistent
          max-width="1000px"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sao chép test set</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogDuplicateTestSet = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <label style="font-size: 16px">
                    Tên test set mới
                  </label>
                  <v-text-field
                      outlined
                      placeholder="Nhập tên test set mới"
                      v-model="nameDuplicateInput"
                      required
                  ></v-text-field>

                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="red darken-1"
                text
                @click="dialogDuplicateTestSet = false"
            >Close
            </v-btn>
            <v-btn outlined color="blue darken-1" text @click="btnDuplicateCourse">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
export default {
  name: "TestSetDuplicateForm",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    test_set: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      is_call_api: false,
      nameDuplicateInput: "",

    };
  },
  computed: {
    dialogDuplicateTestSet: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  watch: {
    dialogDuplicateTestSet(val) {
      if (val) {
        this.resetFormInput();

      }
    },
  },
  methods: {
    resetFormInput() {
      this.nameDuplicateInput = '';
    },
    setTitleCourse() {
      this.nameDuplicateInput = (this.test_set.name ?? '') + ' (Copy)';
    },
    async btnDuplicateCourse() {
      let vm = this;
      let data = {
        name: this.nameDuplicateInput,
        test_set_id: this.test_set.id,
      };
      this.is_call_api = true;
      await ApiService.post("prep-app/test-set/duplicate", data)
          .then(function (res) {
            if (res.status == 200) {
              vm.successMess('Duplicate thành công')
              vm.dialogDuplicateTestSet = false;
              vm.is_call_api = false;
              vm.$emit("resetData");
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error) {
              if ([422].includes(error.response.status) ) {
                vm.errorMess(error.response.data.message);
              } else {
                vm.errorMess('Có lỗi xảy ra. Vui lòng liên hệ bộ phận kỹ thuật hỗ trợ');
              }
            }
          });
    },
    successMess(mess = '') {
      this.$toasted.success(mess, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    errorMess(msg = '') {
      this.$toasted.error(msg, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
  }
}
</script>

<style scoped>

</style>